<template>
	<div>
		<Template-base-empty class="containerCard">
			<div class="container-row-main-s" :style="'width: calc(100% + 30px) !important;'">
				<div class="container-head-s">
					<span class="container-head-title">Meu Perfil</span>
				</div>
				<div class="container-user-s">
					<div class="head-row-s row-dark">
						<b-col cols="6" class="text-head-row-s text-parcela">
							<span>
								Usuário
							</span>
						</b-col>
						<b-col cols="6" class="text-head-row-s">
							<b-form-input
								name="usuario"
								type="text"
								autocomplete="off"
								class="input-text label-conta text-parcela"
								:disabled="true"
								v-model="usuario.usuario"
								v-validate="{ required: true }"
								:state="validateState('usuario')"
							/>
						</b-col>

					</div>
				</div>
				<div class="container-user-s">
					<div class="head-row-s">
						<b-col cols="6" class="text-head-row-s text-parcela">
							<span>
								Nome
							</span>
						</b-col>
						<b-col cols="6" class="text-head-row-s">
							<b-form-input
								name="nome"
								type="text"
								autocomplete="off"
								class="input-text label-conta text-parcela"
								:disabled="loading"
								v-model="usuario.nome"
								v-validate="{ required: true }"
								:state="validateState('nome')"
							/>
						</b-col>
					</div>
				</div>
				<div class="container-user-s">
					<div class="head-row-s row-dark">
						<b-col cols="6" class="text-head-row-s text-parcela">
							<span>
								E-mail
							</span>
						</b-col>
						<b-col cols="6" class="text-head-row-s">
							<b-form-input
								name="email"
								type="text"
								autocomplete="off"
								class="input-text label-conta text-parcela"
								:disabled="true"
								v-model="usuario.email"
								v-validate="{ required: false }"
								:state="validateState('email')"
							/>
						</b-col>
					</div>
				</div>
				<div class="container-user-s mt-2">
					<div class="head-row-s">
						<b-col cols="6" class="text-head-row-s text-parcela">
							<span>
								Senha
							</span>
						</b-col>
						<b-col cols="6" class="text-head-row-s">
							<InputSenha
								class="input-text label-conta text-parcela"
								id="senha"
								name="senha"
								v-model="usuario.senha"
								v-validate="{ required: false }"
								:state="validateState('senha')"
								placeholder="Senha inalterada"
							/>
						</b-col>
					</div>
				</div>
				<div class="container-user-s">
					<div class="head-row-s row-dark">
						<b-col cols="6" class="text-head-row-s text-parcela">
							<span>
								Nome Fantasia
							</span>
						</b-col>
						<b-col cols="6" class="text-head-row-s">
							<b-form-input
								name="enterprise"
								type="text"
								autocomplete="off"
								class="input-text label-conta text-parcela"
								:disabled="loading"
								v-model="usuario.enterprise"
								v-validate="{ required: false }"
								:state="validateState('enterprise')"
							/>
						</b-col>
					</div>
				</div>
				<div class="container-user-s">
					<div class="head-row-s">
						<b-col cols="6" class="text-head-row-s text-parcela">
							<span>
								Endereço
							</span>
						</b-col>
						<b-col cols="6" class="text-head-row-s">
							<b-form-input
								name="address"
								type="text"
								autocomplete="off"
								class="input-text label-conta text-parcela"
								:disabled="loading"
								v-model="usuario.address"
								v-validate="{ required: false }"
								:state="validateState('address')"
							/>
						</b-col>
					</div>
				</div>
				<div class="container-user-s">
					<div class="head-row-s row-dark">
						<b-col cols="6" class="text-head-row-s text-parcela">
							<span>
								CPF/CNPJ
							</span>
						</b-col>
						<b-col cols="6" class="text-head-row-s">
							<b-form-input
								name="identity"
								type="text"
								inputmode="numeric"
								autocomplete="off"
								class="input-text label-conta text-parcela"
								:disabled="loading"
								v-model="usuario.identity"
								v-validate="'validateCPF'"
								:state="validateState('identity')"
								@blur="formatDocument()"
								@input="inputValidate('identity')"
							/>
						</b-col>

					</div>
				</div>
				<section v-if="emailService">
					<div class="container-user-s">
						<div class="head-row-s">
							<b-col cols="6" class="text-head-row-s text-parcela">
								<span>
									E-mail Serviço
								</span>
							</b-col>
							<b-col cols="6" class="text-head-row-s">
								<b-form-input
									name="user"
									type="text"
									autocomplete="off"
									class="input-text label-conta text-parcela"
									:disabled="loading"
									v-model="usuario.user"
									v-validate="{ required: false }"
									:state="validateState('user')"
								/>
							</b-col>
						</div>
					</div>
					<div class="container-user-s">
						<div class="head-row-s row-dark">
							<b-col cols="6" class="text-head-row-s text-parcela">
								<span>
									Servidor SMTP
								</span>
							</b-col>
							<b-col cols="6" class="text-head-row-s">
								<b-form-input
									name="host"
									type="text"
									autocomplete="off"
									class="input-text label-conta text-parcela"
									:disabled="loading"
									v-model="usuario.host"
									v-validate="{ required: false }"
									:state="validateState('host')"
								/>
							</b-col>
						</div>
					</div>
					<div class="container-user-s">
						<div class="head-row-s">
							<b-col cols="6" class="text-head-row-s text-parcela">
								<span>
									Porta
								</span>
							</b-col>
							<b-col cols="6" class="text-head-row-s">
								<b-form-input
									name="port"
									type="text"
									inputmode="numeric"
									autocomplete="off"
									class="input-text label-conta text-parcela"
									:disabled="loading"
									v-model="usuario.port"
									v-validate="{ required: false }"
									:state="validateState('port')"
									@input="inputValidate('port')"
								/>
							</b-col>
						</div>
					</div>
					<div class="container-user-s">
						<div class="head-row-s row-dark mb-2">
							<b-col cols="6" class="text-head-row-s text-parcela">
								<span>
									SSL/TLS
								</span>
							</b-col>
							<b-col cols="6" class="text-head-row-s">
								<ToggleSwitch
									name="secure"
									class="toggle"
									:value="usuario.secure"
									@toggle="_ => changeToggle(usuario.secure)"
								/>
							</b-col>
						</div>
					</div>
					<div class="container-user-s mt-2">
						<div class="head-row-s">
							<b-col cols="6" class="text-head-row-s text-parcela">
								<span>
									Senha Aplicativo
								</span>
							</b-col>
							<b-col cols="6" class="text-head-row-s">
								<InputSenha
									class="input-text label-conta text-parcela"
									id="pass"
									name="pass"
									v-model="usuario.pass"
									v-validate="{ required: false }"
									:state="validateState('pass')"
									placeholder="Senha inalterada"
								/>
							</b-col>
						</div>
					</div>
				</section>
				<div class="container-salvar mt-3 mb-3">
					<b-button
						class="mr-4 float-right btn-modal btn-salvar"
						@click="salvar({})"
						:disabled="invalidForm"
					>
						Salvar
					</b-button>
				</div>
			</div>
		</Template-base-empty>
	</div>
</template>

<script>
	import TemplateBaseEmpty from "@/templates/BaseEmpty";
	import { createHash } from "crypto";
	import { AuthService } from "../../services/auth";
	import InputSenha from "@/components/InputSenha";

	import { UsersService } from "../../services/users";
	import { hasFieldsWithErrors } from "@/helpers/validators";
	import ToggleSwitch from "@/components/ToggleSwitch";
	import { possuiPermissao } from "../../helpers/permissions";
	import { formatIdentityDocument } from "@/helpers/validateIndentity";
	import { validateNumber } from "@/helpers/common";

	export default {
		components: {
			TemplateBaseEmpty,
			InputSenha,
			ToggleSwitch
		},

		inject: ["parentValidator"],

		data () {
			return {
				ordensProducao: [],
				selectMaquinas: [],
				usuario: {
					id: null,
					usuario: null,
					nome: null,
					email: null,
					enterprise: null,
					senha: null,
					secure: false,
					host: "smtp.gmail.com",
					port: 587,
					address: null,
					identity: null,
					telephone: null,
					pass: null,
					user: null
				},

				loading: false,
				invalidForm: true,
				usersService: new UsersService(),
				authService: new AuthService(),

				errMsg: "",
				emailService: possuiPermissao("EMAIL_SERVICE")
			};
		},

		watch: {
		},

		created () {
			this.$validator = this.parentValidator;
		},

		async mounted () {
			this.isLoggedIn = await this.authService.isLoggedIn();
			if (!this.isLoggedIn)
				this.$router.push({ path: "/" });
			else
				this.findUser();
		},

		methods: {
			async findUser () {
				const {
					idUsuario,
					usuario,
					nomeUsuarioCompleto,
					email,
					addressEnterprise,
					identityEnterprise,
					telephoneEnterprise,
					enterprise,
					emailConfig
				} = JSON.parse(localStorage.params);
				//const userProfile = await this.usersService.getUserByIdUser({ idUser: idUsuario });

				this.invalidForm = true;
				this.usuario.usuario = usuario;
				this.usuario.nome = nomeUsuarioCompleto;
				this.usuario.email = email;
				this.usuario.senha = null;
				this.usuario.address = addressEnterprise;
				this.usuario.identity = identityEnterprise;
				this.usuario.telephone = telephoneEnterprise;
				this.usuario.enterprise = enterprise;
				this.usuario.user = emailConfig.user;
				this.usuario.host = emailConfig.host;
				this.usuario.port = emailConfig.port;
				this.usuario.secure = emailConfig.secure;
				this.usuario.pass = emailConfig.pass;
			},

			async salvar () {
				const config = {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				};

				await this.$snotify.async("Aguarde...", "Salvando!", async () => {
					try {
						const payload = {};
						payload.user = this.usuario.usuario;
						payload.name = this.usuario.nome;
						payload.email = this.usuario.email;
						payload.enterprise = this.usuario.enterprise;
						payload.addressEnterprise = this.usuario.address;
						payload.identityEnterprise = this.usuario.identity;
						payload.telephoneEnterprise = this.usuario.telephone;
						payload.emailConfig = {
							host: this.usuario.host,
							port: this.usuario.port,
							secure: this.usuario.secure || false,
							user: this.usuario.user
						};
						if (this.usuario.senha)
							payload.password = createHash("sha512").update(this.usuario.senha).digest("hex");

						if (this.usuario.pass)
							payload.emailConfig.pass = this.usuario.pass;

						const usuarioLogado = await this.usersService.updateProfile({ payload });
						this.$store.commit("setUsuarioLogado", null);
						this.$store.commit("setUsuarioLogado", usuarioLogado);
						this.$router.replace(this.$route.params.next || "/");

						return {
							title: "Sucesso!",
							body: "Usuário salvo com sucesso.",
							config
						};
					} catch (error) {
						const msgError = error.response.data.message || "Erro ao tentar salvar usuário!";
						if (error.response.data.type && error.response.data.type === "emailService") {
							this.$router.replace(this.$route.params.next || "/");
							config.type = "warning";
							config.timeout = 4000;
							return {
								title: "Alerta!",
								body: `Usuário salvo com sucesso. "${msgError}"`,
								config
							};
						} else {
							throw {
								title: "Falha!",
								body: msgError,
								config
							};
						}
					}
				});
			},

			validateState (ref) {
				this.invalidForm = hasFieldsWithErrors(this.veeFields);
				if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated))
					return !this.veeErrors.has(ref);

				return null;
			},

			changeToggle (status) {
				this.usuario.secure = !status;
			},

			formatDocument () {
				this.usuario.identity = formatIdentityDocument(this.usuario.identity);
			},

			async inputValidate (key) {
				this.usuario[key] = await validateNumber(this.usuario[key]);
			}
		}
	};
</script>

<style scoped>
	.container-salvar {
		display: flex;
		width: 100%;
		height: 3rem;
		justify-content: right;
	}

	.salvar-button {
		width: 135;
		height: 43;
		font-family: "Roboto Condensed Medium";
		background-color: #218838;
		color: #FFFFFF;
		font-size: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 5px;
		padding: .75rem 1.5rem;
		border-style: none;
		margin-bottom: .5rem;
	}

	.container-row-main-s {
		margin-left: -15px;
		background-color: #FFFFFF;
		border-radius: 10px;
		box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
	}

	.container-head-s {
		display: flex;
		/* background-color: #6dcff6 ; */
		background-color: var(--cal-header-template);
		border-radius: 10px 10px 0 0;
		padding-top: 0.5rem;
		margin-bottom: 2rem;
	}

	.container-user-s {
		margin-left: 1.5rem;
	}

	.container-head-title {
		width: 50%;
		height: 3rem;
		margin-left: 16px;
		margin-top: -5px;
		font-family: "Roboto Condensed Medium";
		color: #FFFFFF;
		font-size: 30px;
	}

	.head-row-s {
		display: flex;
		align-items: center;
		width: calc(100% - 24px);
		height: 4rem;
	}

	.row-dark {
		background-color: #eaebec;
	}

	.text-head-row-s {
		align-items: center;
		/* font-family: "Roboto Condensed Regular";
		font-size: 20px; */
	}

	.input-text {
		align-items: center;
		/* font-family: "Roboto Condensed Regular";
		font-size: 20px; */
	}

	.btn-cancel {
		background-color: #343A40;
		border-radius: 4px 0 0 4px;
		border-style: none;
	}

	.custom-number-input {
		padding-right: 35px !important;
	}
</style>
